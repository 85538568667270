<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ item.tracking_id }}
      <v-spacer></v-spacer>
      <v-btn v-if="!isActive" color="success" @click="save" class="mr-2">
        {{ $t("labels.confirm") }}
      </v-btn>
      <v-btn color="error" outlined @click="close">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="12">
          <v-simple-table class="table-padding-2-no-top table-h-36">
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th colspan="7" class="fs-16px primary--text">
                    {{ $t("labels.machine_file") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="font-weight-medium text-center">
                  <td>Ảnh</td>
                  <td>SKU</td>
                  <td>{{ $t("labels.barcode") }}</td>
                  <td>Tên</td>
                  <td>Đặc tả</td>
                  <td>Size</td>
                  <td>File</td>
                </tr>
                <tr class="text-center">
                  <td>
                    <ImageViewer
                      v-if="item.url_images"
                      :url="item.url_images"
                      width="40px"
                      height="40px"
                    />
                  </td>
                  <td>{{ item.sku }}</td>
                  <td>{{ item.customer_goods_barcode }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.size }}</td>
                  <td>
                    <template v-if="item.level === 0">
                      <span
                        class="warning--text font-weight-medium cursor-pointer"
                        @click="$refs.inputUploadFile.click()"
                      >
                        Upload
                      </span>
                      |
                    </template>
                    <span
                      class="primary--text font-weight-medium cursor-pointer"
                      @click="downloadRecordFile"
                    >
                      Download
                    </span>
                    <input
                      type="file"
                      ref="inputUploadFile"
                      accept="image/*,application/pdf"
                      @change="onInputFileChange"
                      class="d-none"
                      multiple
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-col cols="12" md="12">
          <RecordDetailSemiFinished
            :semi-finished="semiFinished"
            :item="item"
            @updateRecordSemiFinished="updateRecordSemiFinished"
          />
        </v-col>

        <v-col cols="12" md="12">
          <RecordDetailSecondaryProcess
            :item="item"
            :record-semi-finished="recordSemiFinished"
            :secondary-processes="secondaryProcesses"
          />
        </v-col>

        <v-col cols="12" md="12">
          <RecordDetailAccessory :item="item" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { compress } from "image-conversion";
import moment from "moment";

export default {
  name: "RecordDetail",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
    RecordDetailSemiFinished: () =>
      import("@/components/goods_production/RecordDetailSemiFinished"),
    RecordDetailAccessory: () =>
      import("@/components/goods_production/RecordDetailAccessory"),
    RecordDetailSecondaryProcess: () =>
      import("@/components/goods_production/RecordDetailSecondaryProcess"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    files: null,
    isLoading: false,
    detailFiles: [],
    semiFinished: [],
    secondaryProcesses: [],
    recordSemiFinished: [],
    isActive: false,
  }),
  computed: {},
  created() {},
  mounted() {
    this.getConfig();
    this.getFile();
    this.isActive = this.item.status == 1;
  },
  methods: {
    async downloadRecordFile() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = `${this.item.tracking_id}.zip`;

      try {
        await this.downloadExcelFile(
          "/download-goods-production-record-file",
          {
            id_goods_production_record: this.item.id,
          },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async onInputFileChange(e) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      let files = e.target.files || e.dataTransfer.files;
      let urls = [];
      for (let i = 0; i < files.length; i++) {
        const url = await this.uploadFile(files[i]);
        urls.push(url);
      }
      this.$refs.inputUploadFile.value = null;

      try {
        await httpClient.post("/goods-production-save-file", {
          files: urls,
          id_goods_production_record: this.item.id,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async uploadFile(file) {
      console.log(file);
      let fd = new FormData();
      if (file.type.includes("image")) {
        const resBlob = await compress(file, {
          quality: 0.85,
          type: "image/jpeg",
        });
        const newFile = new File(
          [resBlob],
          `${moment().unix()}_${this.generateRandomString(6)}.jpg`,
          { type: "image/jpeg" }
        );
        fd.append("file", newFile);
      } else {
        fd.append("file", file);
      }
      const { data } = await httpClient.post(`/upload-image`, fd);
      return data;
    },

    close() {
      this.$emit("close", true);
    },

    updateRecordSemiFinished(recordSemiFinished) {
      this.recordSemiFinished = [...recordSemiFinished];
    },

    async save() {
      if (
        !confirm(
          this.$t("messages.goods_production_record_confirm", {
            tracking: this.item.tracking_id,
          })
        )
      ) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/goods-production-confirm", {
          id: this.item.id,
        });
        this.isLoading = false;
        this.isActive = true;
        const msg = this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    async getConfig() {
      try {
        const { data } = await httpClient.post("/goods-production-get-config");
        this.isLoading = false;
        this.semiFinished = [...data.semi_finished];
        this.secondaryProcesses = [...data.secondary_processes];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    async getFile() {
      try {
        const { data } = await httpClient.post("/goods-production-get-file", {
          id_goods_production_record: this.item.id,
        });
        this.isLoading = false;
        this.detailFiles = [...data];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
